import { useApi } from '~/composables/api/useApi'

export function useUser() {
  const { fetchWithAuth } = useApi()
  const config = useRuntimeConfig()
  const RESOURCE = '/user'
  const configAPI = config.public.configAPI

  // GET Requests

  async function fetchUsersById(providedOptions: { userId: number }) {
    return await fetchWithAuth(
      `${RESOURCE}/${providedOptions.userId}`,
      'GET',
      {}
    )
  }

  async function findUser(providedOptions: {
    mail?: string
    firstName?: string
    lastName?: string
  }) {
    const data = await fetchWithAuth(`${RESOURCE}/list`, 'GET', {
      providedOptions: {
        user: providedOptions.mail,
        firstName: providedOptions.firstName,
        lastName: providedOptions.lastName,
      },
    })
    return data?.trpUser
  }

  async function fetchUserConfig(providedOptions: { userId: number }) {
    return await fetchWithAuth(
      `${configAPI}/v1/user/${providedOptions.userId}`,
      'GET',
      {}
    )
  }

  async function fetchEditorSettings(providedOptions: { userId: number }) {
    return await fetchWithAuth(
      `${configAPI}/v1/user/${providedOptions.userId}/editor`,
      'GET',
      {}
    )
  }

  async function fetchPageSettings(providedOptions: { userId: number }) {
    return await fetchWithAuth(
      `${configAPI}/v1/user/${providedOptions.userId}/pages`,
      'GET',
      {}
    )
  }

  async function fetchDocumentSettings(providedOptions: { userId: number }) {
    return await fetchWithAuth(
      `${configAPI}/v1/user/${providedOptions.userId}/documents`,
      'GET',
      {}
    )
  }

  async function fetchCollectionSettings(providedOptions: { userId: number }) {
    return await fetchWithAuth(
      `${configAPI}/v1/user/${providedOptions.userId}/collections`,
      'GET',
      {}
    )
  }

  async function updateEditorSettings(options: {
    userId: number
    settings: any
  }) {
    return await fetchWithAuth(
      `${configAPI}/v1/user/${options.userId}/editor`,
      'PUT',
      { data: options.settings }
    )
  }

  async function updatePageSettings(options: {
    userId: number
    settings: any
  }) {
    return await fetchWithAuth(
      `${configAPI}/v1/user/${options.userId}/pages`,
      'PUT',
      { data: options.settings }
    )
  }

  async function updateDocumentSettings(options: {
    userId: number
    settings: any
  }) {
    return await fetchWithAuth(
      `${configAPI}/v1/user/${options.userId}/documents`,
      'PUT',
      { data: options.settings }
    )
  }

  async function updateCollectionSettings(options: {
    userId: number
    settings: any
  }) {
    return await fetchWithAuth(
      `${configAPI}/v1/user/${options.userId}/collections`,
      'PUT',
      { data: options.settings }
    )
    console.log('PUT collection settings', options)
  }

  // POST Requests

  async function updateUserConfig(providedOptions: {
    userId: number
    config: any
  }) {
    return await fetchWithAuth(
      `${configAPI}/v1/user/${providedOptions.userId}`,
      'POST',
      {
        data: providedOptions.config,
      }
    )
  }

  return {
    fetchUsersById,
    findUser,
    fetchUserConfig,
    updateUserConfig,
    fetchEditorSettings,
    fetchPageSettings,
    fetchDocumentSettings,
    fetchCollectionSettings,
    updateEditorSettings,
    updatePageSettings,
    updateDocumentSettings,
    updateCollectionSettings,
  }
}
